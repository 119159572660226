<template>
	<div>
		<van-tabs v-model="tabActive"  sticky @click="getCourse">
			 <van-tab :title="item.parent.text" v-for="item in data.cate" :key="item.parent.id">
				 <van-tree-select height="calc(100vh - 45px)"  :items="item.child" :main-active-index.sync="courseActive" >
				 	<template #content>
					  <van-empty description="暂无课程" v-show="courseList.length <= 0"/>
						<a class="course-item" v-for="(item2,index2) in courseList" :key="index2" >
							<div style="width: 100%;height: 100%;top: 0px; left: 0px;z-index: 0;padding: 20px 14px;box-sizing: border-box;">
								<div class="coures-item-name">{{item2.title}}</div>
								<div class="coures-item-author">
									<span>主讲老师:{{item2.teacher}}</span>
									<van-tag type="success">查看课程</van-tag>
								</div>
								<div class="coures-item-con">{{item2.contents}} </div>
							</div>
							<wx-open-launch-weapp id="launch-btn" username="gh_31847d4a6326" :path="item2.url">
								 <script type="text/wxtag-template">
									<style>
									  .ctn{width: 120px;height: 120px;}
									</style>
									 <div class="ctn"></div>
								 </script>
							</wx-open-launch-weapp>
						</a> 
				 	</template>
				 </van-tree-select>
			 </van-tab>
		</van-tabs>	
	</div>
</template>

<script>

export default {
	created(){
		// this.wxUserName = process.env.VUE_APP_WXID ? process.env.VUE_APP_WXID : 'gh_87ea9f74ba25'
		if(window.sessionStorage.getItem('is_couese') != 'true'){
			this.$router.push({path:'/home'})
		}
		axios.get(
			"/get_course"
		).then(res=>{
			if(res.code != 1){
				this.$toast(res.message)
			}
			else{
				this.data = res.data
				this.courseList = res.data.course
			}
			// console.log(res);
		})
	},
	data() {
		return {
			data:{},
			tabActive:0,   //大分类序号
			courseActive: 0,  //小分类序号
			courseList:[],
			wxUserName:''
		};
	},
	methods:{
		getCourse(){
			
			this.courseActive = 0
			let courseId = this.data.cate[this.tabActive].child[this.courseActive].id
			axios.get(
				"/get_course",
				{params:{course_cate_id:courseId}}
			).then(res=>{
				if(res.code != 1){
					this.$toast(res.message)
				}
				else{
					this.courseList = res.data.course
				}
			})
		}
	},
	watch:{
		courseActive(){
	
			let courseId = this.data.cate[this.tabActive].child[this.courseActive].id
			axios.get(
				"/get_course",
				{params:{course_cate_id:courseId}}
			).then(res=>{
				if(res.code != 1){
					this.$toast(res.message)
				}
				else{
					this.courseList = res.data.course
				}
			})
		}
	}
};
</script>

<style scoped>
	#launch-btn {
	    z-index: 10;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: transparent;
	}
	>>> .van-tag--success{
		color: white !important;
	}
	>>> .van-tabs--line .van-tabs__wrap{
		border-bottom: 1px solid #ebedf0;
		
	}
	>>> .van-tabs__nav{
		background-color: #ebedf0;
	}
	.van-sidebar-item--select, .van-sidebar-item--select:active{
		color: #ee0a24;
		font-weight: 700;
	}
	.van-sidebar-item--select::before{
		height: 19px !important;
	}
	.course-item{
		display: block;
		width: 100%;
		/* padding: 20px 14px; */
		box-sizing: border-box;
		position: relative;
		word-break: break-all;
		border-bottom: 1px dashed #ebedf0;
		color: black;
	}
	.van-tree-select__content .course-item:last-of-type{
		border: none;
	}
	.course-item::after{
		display: block;
		content: '';
		clear: both;
	}
	.coures-item-name{
		font-style: normal;
	}
	.coures-item-author{
		font-size: 0.8rem;	
		margin: 5px 0px;
		display: flex;
		display: -webkit-flex;
		align-items: center;
		justify-content: space-between;
	}
	.coures-item-author>span{
		margin: 0px;
		color: #939393;
	}
	.coures-item-con{
		font-size: 0.8rem;
		word-break: break-all;
		color: #696a6b;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		width: 100%;
		overflow: hidden;
	}
</style>
